<template>
   <v-container grid-list-md>
      <v-layout row wrap>
         <v-flex xs12 md6>
            <h1>Usuarios</h1>
         </v-flex>
      </v-layout>
      <v-layout row wrap mt-2>
         <!--Listado de usuarios -->
         <v-flex >
            <v-card elevation="1" >
               <v-simple-table dense>
                  <template v-slot:top>
                     <v-toolbar flat>
                        <input type="text" v-model="search" placeholder="Buscar usuario..." class="txtSearch"/>                                          
                        <div class="flex-grow-1"></div>
                        <v-btn class="mr-2" color="primary" elevation="2" x-small @click="add()">
                           <v-icon small>mdi-plus</v-icon>
                           Añadir
                        </v-btn>
                     </v-toolbar>
                  </template>
                  <template>
                     <thead>
                        <tr>
                           <th>Usuario</th>
                           <th>Tipo</th>
                           <th>Fecha creación</th>
                           <th>Grupos</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr v-for="item in usersFiltered" :key="item.username" v-on:click="onUserSelected(item)">
                           <td>{{ item.username }}</td>
                           <td>
                              {{ item.type == "AD" ? "Directorio Activo" : "Invitado" }}
                           </td>
                           <td>{{ new Date(item.createdAt).toLocaleDateString() }}</td>
                           <td>{{ item.groups? getGroupsName(item.groups): "" }}</td>
                           <td class="text-right" style="width:115px">
                              <v-btn
                                 class="ml-1"
                                 outlined
                                 color="primary"
                                 elevation="2"
                                 x-small                                 
                                 @click="deleteUser(item)">
                                 <v-icon small>mdi-delete</v-icon>
                              </v-btn>
                           </td>
                        </tr>
                     </tbody>
                  </template>
               </v-simple-table>
            </v-card>
         </v-flex>
      </v-layout>
      <vue-confirm-dialog></vue-confirm-dialog>
      <v-dialog v-model="editorDialog" max-width="500">
         <v-card>
            <v-card-title class="headline grey lighten-2">
               Usuario {{ userItem.type && userItem.type == "AD" ? "del Directorio Activo" : "Invitado" }}
            </v-card-title>
            <v-form ref="form" lazy-validation @keyup.native.enter="submit">
               <v-container>
                  <v-card-text>
                     <v-container class="pb-0" fluid>
                        <v-row>
                           <v-col cols="12">
                              <v-text-field
                                 label="Usuario"
                                 v-model="userItem.username"
                                 filled
                                 hide-details="auto"
                                 prepend-icon="mdi-account"
                                 :rules="requiredRule"
                                 :readonly="editMode"
                                 ></v-text-field>
                           </v-col>
                           <v-col cols="12">
                              <v-text-field
                                 label="Contraseña"
                                 v-model="userItem.password"
                                 filled
                                 clearable
                                 v-show="userItem.type == 'guest'"
                                 hide-details="auto"
                                 :rules="requiredRule"
                                 prepend-icon="mdi-lock"
                                 ></v-text-field>
                           </v-col>
                        </v-row>
                     </v-container>
                  </v-card-text>
               </v-container>
            </v-form>
            <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
            </v-progress-linear>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="primary" text @click="save" v-show="userItem.type == 'guest'"> Guardar </v-btn>
               <v-btn color="primary" text @click="editorDialog = false">
                  Cancelar
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-container>
</template>

<script>
import Vue from "vue";
import VueConfirmDialog from "vue-confirm-dialog";

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

export default {
  name: "Users",
  components: {},
  data: () => ({
    userSelected: {},
    requiredRule: [v => (v != null && v != "" ) || "Campo obligatorio"],
    editorDialog: false,
    editMode: false,
    userItem: {},
    search: '',
  }),
  created() {
    this.$store.dispatch("fetchUsers");
    this.$store.dispatch("fetchGroups");
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    users() {
      return this.$store.getters.getUsers;
    },

    usersFiltered() {
      if (!this.users)
        return [];

      return this.users.filter(u => {
        return u.username.toLowerCase().includes(this.search.toLowerCase()) || u.userId.includes(this.search.toLowerCase())
      })
    },

    channels() {
      return this.$store.getters.getChannels;
    },
    groups() {
      return this.$store.getters.getGroups;
    },
  },
  methods: {

    onUserSelected: function(item) {
      this.userSelected = item;      
    },
    add: function() {
      this.userItem = { type:"guest"};
      this.editorDialog = true;
      this.editMode = false;
    },
    save: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        this.$store
          .dispatch("saveUser", this.userItem)
          .then(() => {
            this.$store.dispatch("fetchUsers");
            this.editorDialog = false;
          })
          .catch(() => {
            this.editorDialog = false;
            alert("No se ha podido guardar el usuario.");
          });
      }
    },
    getGroupsName: function(vgroups) {

      let groupsText = "";

      this.groups.filter(g => vgroups.indexOf(g._id) >= 0).forEach(e => {
        groupsText += e.name + ", ";
      }); 
      if (groupsText.length > 0)
        groupsText = groupsText.substring(0, groupsText.length -2);

      return groupsText;
    },
    deleteUser: function(item) {
      this.$confirm({
        title: "Eliminar usuario",
        message: "¿Deseas eliminar el usuario?",
        button: {
          yes: "Sí",
          no: "No",
        },
        callback: confirm => {
          if (confirm) {
            this.$store
              .dispatch("deleteUser", item.username)
              .then(() => {
                this.$store.dispatch("fetchUsers");
              })
              .catch(() => {
                alert("No se ha podido eliminar el usuario.");
              });
          }
        },
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding-bottom: -4px !important;
  margin-bottom: -30px !important;
}
.txtSearch
{
  border: 1px solid #ccc;
  padding: 5px;
  border-radius:3px;
}

</style>
